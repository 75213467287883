import { css } from '@emotion/react'
import type { SubscriptionSummary } from '@orus.eu/backend/src/views/pending-subscriptions-view'
import { Avatar, Button, ContentContainerBackoffice, PersistentNotification, spacing } from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { memo, type ReactElement } from 'react'
import { usePermissions } from '../../../../../lib/use-permissions'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { BackofficePendingSubscriptionsTable } from '../../../../organisms/backoffice-pending-subscriptions-table'

type PendingSubscriptionsListContentProps = {
  isCreating: boolean
  createSubscription: () => void
  isSubscriptionListLoading: boolean
  subscriptionList: SubscriptionSummary[] | undefined
  hasSubscriptionListError: boolean
}

export const PendingSubscriptionsListContent = memo<PendingSubscriptionsListContentProps>(
  function PendingSubscriptionsListContent({
    isCreating,
    createSubscription,
    isSubscriptionListLoading,
    subscriptionList,
    hasSubscriptionListError,
  }) {
    const { permissions } = usePermissions()

    return (
      <ContentContainerBackoffice marginTop={spacing[70]}>
        <Header
          rightButtonGroups={[
            permissions.includes('subscription.create') ? (
              <Button
                key="new-quote"
                avatar={<Avatar icon="plus-solid" />}
                avatarPosition="left"
                variant="primary"
                size="small"
                title="Nouveau devis"
                onClick={createSubscription}
                isLoading={isCreating}
              >
                Nouveau devis
              </Button>
            ) : undefined,
          ]}
          title="Devis"
        />

        {
          // We don't have a lot of graceful degradation in the app, but here we need one
          // if for some reason the list of subscriptions cannot be loaded, it's important to still be capable
          // of creating new subscriptions, which is why we don't crash all the page

          isSubscriptionListLoading ? (
            <GlobalLoadingState />
          ) : hasSubscriptionListError ? (
            <PersistentNotification
              css={css`
                margin-top: ${spacing[70]};
              `}
              variant="danger"
            >
              Impossible de charger la liste des souscription, la tech a reçu une alerte
            </PersistentNotification>
          ) : subscriptionList ? (
            <PendingSubscriptionsList subscriptions={subscriptionList} />
          ) : undefined
        }
      </ContentContainerBackoffice>
    )
  },
)

type PendingSubscriptionsListProps = {
  subscriptions: SubscriptionSummary[]
}

function PendingSubscriptionsList({ subscriptions }: PendingSubscriptionsListProps): ReactElement {
  return <BackofficePendingSubscriptionsTable subscriptions={subscriptions} sx={{ marginTop: spacing[60] }} />
}
